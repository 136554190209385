<div class="flex flex-col flex-auto min-w-0">
    <div class="flex-auto p-2 sm:p-10">
        <div class="content header">  
            <div class="flex items-center justify-between w-full ">
                <h2  class="headers text-3xl font-semibold tracking-tight leading-8 flex-auto"
                    i18n="Inventories Overview Component header">
                    {{ this.isNike ? ('NIKE_REPORT.PAGE_HEADER' | translate) : this.organization_name + ' '+ ('BRAND_MONTHLY_REPORT.PAGE_HEADER' | translate) }}
                </h2>
            </div>          

            <div>
                <h2 class="headers page-sub-header" i18n="Inventories Overview Component sub header">
                    {{(this.isNike ? 'NIKE_REPORT.PAGE_SUB_HEADER' : 'BRAND_MONTHLY_REPORT.PAGE_SUB_HEADER') | translate }}
                </h2>
            </div>
        </div> 
        <div class="content mt-4 ">  
            <div class="flex flex-row">
                <!-- <mat-form-field *ngIf="this.isKontoor" appearance="fill">
                    <mat-label i18n="Inventories report date range label">{{
                        'INVENTORIES_REPORT_COMPONENT.CHART.DATE_RANGE' | translate }}</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate placeholder="Start date" [formControl]="startDate">
                        <input matEndDate placeholder="End date" [formControl]="endDate" (dateChange)="changeEndDate($event)">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field> -->

                <mat-form-field *ngIf="!this.isNike">
                    <mat-label>Month and Year</mat-label>
                    <input matInput [matDatepicker]="dp" [formControl]="date">
                    <mat-hint>MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp
                            startView="multi-year"
                            (monthSelected)="setMonthAndYear($event, dp)"
                            panelClass="example-month-picker">
                    </mat-datepicker>
                </mat-form-field>

                <div [ngClass]="isNike ? 'exportNike' : 'ml-4 mt-8'">
                    <button *ngIf="!this.isInditex" class="btn btn-primary" style="color:white" (click)="exportData()">                       
                   {{(this.isNike ? 'NIKE_REPORT.DOWNLOAD' : 'BRAND_MONTHLY_REPORT.PAGE_HEADER') | translate }} 
                    <mat-icon style="color:white; vertical-align: middle" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                    </button>
                    <button *ngIf="this.isInditex" class="btn btn-primary" style="color:white" (click)="exportData()">                       
                   {{'BRAND_MONTHLY_REPORT.PAGE_HEADER' | translate }} Excel
                    <mat-icon style="color:white; vertical-align: middle" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                    </button>
                    <button *ngIf="this.isInditex" class="btn btn-primary" style="color:white; margin-left: 17px;" (click)="exportDataJson()">                       
                   {{ 'BRAND_MONTHLY_REPORT.PAGE_HEADER' | translate }} Json
                    <mat-icon style="color:white; vertical-align: middle" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                    </button>
                </div>
            </div>  

            <div *ngIf="this.isAldi || this.isHofer">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListAldiHoffer" #monthlyDataAldiHofferMatSort="matSort" matSort>

                        <ng-container matColumnDef="factory_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.factory_name}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="brand_id">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> 
                                    <ng-container *ngIf="this.isAldi">
                                    ALDI ID
                                    </ng-container>
                                    <ng-container *ngIf="this.isHofer">
                                    Aldi Süd KG ID
                                    </ng-container>
                                </th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.brand_id}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="date_of_inventory">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Inventory</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.date_of_inventory | date:'yyyy-MM'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total_number_of_chemicals">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Number of Chemicals</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.total_number_of_chemicals}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="consumption">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                            <td mat-cell *matCellDef="let formula">
                                {{ formula.consumption != 'NA' ? (formula.consumption | number : '1.2-2') : 'NA'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total_production_facility">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Production Facility</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.total_production_facility | number:'1.2-2'}}
                            </td>
                        </ng-container>

                         <!-- <ng-container matColumnDef="aldi_number_of_chemicals">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                ALDI Group Number of Chemicals
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.aldi_number_of_chemicals}}
                            </td>
                        </ng-container> -->

                        <ng-container matColumnDef="brand_production">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                ALDI Group Production
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.brand_production}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="aldi_hoffer_monthlyData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: aldi_hoffer_monthlyData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingMonthlyDataInfoAldiHoffer"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataAldiHofferPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div>
            <div *ngIf="this.isAldiImporterBrand">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListAldiImporterBrand" #monthlyDataAldiImporterBrandMatSort="matSort" matSort>

                        <ng-container matColumnDef="factory_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.factory_name}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="brand_id">
                                  <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand ID</th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.brand_id}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="date_of_inventory">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Inventory</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.date_of_inventory | date:'yyyy-MM'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total_number_of_chemicals">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Number of Chemicals
                                <mat-icon class="info_icon"  svgIcon="mat_outline:info" matTooltip="Total Number of all chemicals disregarding ALDI / {{ organization_name }} production (based on ZDHC InCheck Scope)"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.total_number_of_chemicals}}
                            </td>
                        </ng-container>

                         <ng-container matColumnDef="consumption">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption</th>
                            <td mat-cell *matCellDef="let formula">
                                {{ formula.consumption != 'NA' ? formula.consumption  : 'NA'}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="total_production_facility">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Production Facility
                                <mat-icon  class="info_icon" svgIcon="mat_outline:info" matTooltip="ZDHC MRSL compliance percentage (based on ZDHC InCheck Scope) of all chemicals disregarding ALDI / {{ organization_name }} production"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.total_production_facility | number:'1.2-2'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_number_of_chemicals">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Aldi Number of Chemicals
                              <mat-icon class="info_icon"  svgIcon="mat_outline:info" matTooltip="Number of chemicals marked as 'Used for ALDI Group' (based on ZDHC InCheck Scope)"></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_number_of_chemicals}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_group_production">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Aldi Group Production
                                <mat-icon class="info_icon"  svgIcon="mat_outline:info" matTooltip="Note: if the field is empty, no chemicals were marked as 'Used for ALDI Group' for this month"></mat-icon>

                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_group_production}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="brand_chemical_count">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                {{ organization_name}} Chemical Count
                                <mat-icon class="info_icon"  svgIcon="mat_outline:info" matTooltip="This information is currently not shared with ALDI "></mat-icon>

                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.brand_chemical_count}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="brand_production">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                               {{ organization_name}} Production
                                <mat-icon class="info_icon" svgIcon="mat_outline:info" matTooltip="Note: if the field is empty , no chemicals were marked as 'Used for {{ organization_name }}' for this month --> This information is currently not shared with ALDI "></mat-icon>
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.brand_production}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="connected_to_aldi">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                               Connected to Aldi

                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_aldi}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="aldi_importer_brands_monthlyData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: aldi_importer_brands_monthlyData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingMonthlyDataInfoAldiImporterBrand"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataAldiImporterBrandPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div>

            <div *ngIf="this.isAldiSourcing">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListAldiSourcing" class="monthlyDataTable" #monthlyDataAldiSourcingMatSort="matSort" matSort>

                        <ng-container matColumnDef="factory_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.factory_name}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="email">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Email 
                                </th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.email}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="country_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.country_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="last_scan_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Scan Date
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.last_scan_date | date:'yyyy-MM-dd'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="has_uploaded_last_month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Has Uploaded Last Month</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.has_uploaded_last_month}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="gatewaySubscription">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Subscription</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.gatewaySubscription}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="connected_to_ALDI_Sourcing">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to ALDI Sourcing
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_ALDI_Sourcing}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="connected_to_ALDI_Eink">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to ALDI Einkauf SE & Co. oHG
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_ALDI_Eink}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="connected_to_Hofer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to Aldi Süd KG
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_Hofer}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemicals_for_ALDI_Sourcing">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemicals for ALDI Sourcing
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.chemicals_for_ALDI_Sourcing}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemicals_for_ALDI_Prod">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemicals for ALDI Group Production
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.chemicals_for_ALDI_Prod}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="compliance_aldi">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Aldi
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.compliance_aldi}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total_uploaded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Chemicals Uploaded
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.total_uploaded}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="compliance_overall">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Overall Compliance
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.compliance_overall}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="aldi_sourcing_monthlyData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: aldi_sourcing_monthlyData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingMonthlyDataInfoAldiSourcing"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataAldiSourcingPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div>

            <!-- <div *ngIf="this.isAldiImporter">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListAldiImporter" #monthlyDataAldiImporterMatSort="matSort" matSort>

                        <ng-container matColumnDef="factory_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.factory_name}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Inventory</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.date | date:'yyyy-MM'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chem_used_for_aldi">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr. Of Chemicals used for ALDI
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.chem_used_for_aldi}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chem_used_for_fl_textil">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr. Of Chemicals for FL Textil</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.chem_used_for_fl_textil}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Nr of Chemicals</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.total_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="general_compliance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>% Compliance In General
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.general_compliance}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_compliance">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>% Compliance ALDI
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_compliance}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="aldi_importer_monthlyData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: aldi_importer_monthlyData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingMonthlyDataInfoAldiImporter"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataAldiImporterPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div> -->

            <div *ngIf="this.isKontoor" >
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListKontoor" #monthlyDataKontoorMatSort="matSort" matSort>

                        <ng-container matColumnDef="Bhive_ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bhive-ID</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Bhive_ID}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Kontoor_ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Kontoor-ID</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Kontoor_ID}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Factory_Name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Factory_Name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="InScope">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>In-Scope/Out-Scope</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.InScope}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Country">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.Country}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="FFC">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>FFC#</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.FFC}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="BHive_Type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>BHive Type</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.BHive_Type }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Factory_Type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Type</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Factory_Type }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Email }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Walmart_Order">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Walmart Order</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Walmart_Order }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Connected_to_Kontoor">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to Kontoor</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Connected_to_Kontoor}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Scan_Date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scan Date</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Scan_Date | date:'MM-yyyy'}}
                        </ng-container>
                                
                        <ng-container matColumnDef="No_of_Chemicals_from_Last_Scan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>No.of Chemicals from Last Scan</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.No_of_Chemicals_from_Last_Scan }}
                            </td>
                        </ng-container>
                                
                        <ng-container matColumnDef="No_of_Chemicals_With_Commodities_from_Last_Scan">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>No.of Chemicals with Commodities from Last Scan</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.No_of_Chemicals_With_Commodities_from_Last_Scan }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Compliance_online_only_Connected">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance - online: only Connected!</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.Compliance_online_only_Connected | number: "1.2-2" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Compliance_With_Commodities_only_Connected">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance with Commodities - online: only Connected!</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.Compliance_With_Commodities_only_Connected | number: "1.2-2" }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Higg">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Higg FEM (Data) 4.0</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.Higg }}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="Wastewater">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Wastewater Data</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.Wastewater }}
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="kontoorData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: kontoorData;"></tr>

                    </table>
                    <loading-spinner *ngIf="isLoadingKontoorDataInfo"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataKontoorPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div>

            <div *ngIf="this.isNike">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="exportNikeCSV" #exportNikeCSVMatSort="matSort" matSort>

                        <ng-container matColumnDef="supplier_ID">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Entity Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula['Entity Name']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="CampaignName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Campaign Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula['Campaign Name']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="questionnaireName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Questionnaire Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula['Questionnaire Name']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="month">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Month</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula['Month']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="vendor">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula['Vendor']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="product">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula['Product']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="solvent_use">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Solvent Use</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula['Solvent Use']}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="index">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Index</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula['Index']}}
                            </td>
                        </ng-container>


                        <tr mat-header-row *matHeaderRowDef="nikeData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: nikeData;"></tr>

                    </table>
                    <loading-spinner *ngIf="isLoadingNikeDataInfo"></loading-spinner>                       
                </div>
                <mat-paginator #exportNikeCSVPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
            </div> 

            <div *ngIf="this.isInditex">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListInditex" class="monthlyDataTable" #monthlyDataInditexMatSort="matSort" matSort>

                        <ng-container matColumnDef="cil_supplier">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>CIL Supplier 
                                </th>
                                <td mat-cell *matCellDef="let formula">
                                     {{formula.cil_supplier}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="factory_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name 
                                </th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.factory_name}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="country_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Country</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.country_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_aid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC AID
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.zdhc_aid}}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="inditex_brand">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inditex Brand
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.inditex_brand}}
                            </td>
                        </ng-container> -->

                        <ng-container matColumnDef="month_of_inventory">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Reporting month/year</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.month_of_inventory}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemical_supplier">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Supplier Name</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.chemical_supplier}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemical_supplier_country">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Supplier Country</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                 {{formula.chemical_supplier_country}}
                                
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemical_product">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Product
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.chemical_product}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_pid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC PID Product
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.zdhc_pid}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_substrate">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC Substrate
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.zdhc_substrate}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_use_category">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Zdhc Use Category
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.zdhc_use_category}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_use_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Zdhc Use Type
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.zdhc_use_type}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="type_of_manufacturing">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type of Manufacturing
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.type_of_manufacturing}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="consumption">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption (kg)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{ formula.consumption != 'NA' ? formula.consumption : 'NA'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_certification_result">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC Certification Level
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.zdhc_certification_result}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="has_at_least_one_standard">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL by BHive Crowdource
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.has_at_least_one_standard}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="verified_standards">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL Source by BHive Crowdource
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.verified_standards}}
                            </td>
                        </ng-container>

                        <!-- <ng-container matColumnDef="zdhc_grading">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL Level by BHive Crowdource
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.zdhc_grading}}
                            </td>
                        </ng-container> -->

                        <ng-container matColumnDef="inditexBelowThreeYears">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>"The List by Inditex" certification level (Baby)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.inditexBelowThreeYears}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="inditexAboveThreeYears">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>"The List by Inditex" certification level (Adult)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.inditexAboveThreeYears}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="substance_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Substance
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.substance_name}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="percentage">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Concentration of Substance %
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.percentage}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="cas_nr">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>CAS Number
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.cas_nr}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="ec_number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>EC Number
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.ec_number}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="echa">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxicity ECHA
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                               {{formula.echa}} 
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="svhc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxicity SVHC
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.svhc}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="toxicity_regulatory_list">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxicity other regulatory list
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.toxicity_regulatory_list}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="inditexData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: inditexData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingInditexDataInfo"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataInditexPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
                    
            </div>
            <div *ngIf="this.isMiles">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListMiles" class="monthlyDataTable" #monthlyDataMilesMatSort="matSort" matSort>

                        <ng-container matColumnDef="factory_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name 
                                </th>
                                <td mat-cell *matCellDef="let formula">
                                    {{formula.factory_name}}
                                </td>
                            </ng-container>

                        <ng-container matColumnDef="country_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Country</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.country_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inventory Date
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.date | date:'yyyy-MM'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="compliance_pct">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance Level (%)
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.compliance_pct | number:'1.2-2'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="total_uploaded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr of Chemicals (Tot)</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.total_uploaded}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="verified_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified Count</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.verified_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="unverified_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unverified Count</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.unverified_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="commodity_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Commodities Count
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.commodity_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="brand_uploaded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nr. Chemicals Aldi
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.brand_uploaded}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_verified_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Verified Count Aldi
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_verified_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_unverified_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Unverified Count Aldi
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_unverified_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_commodity_chem">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Commodities Count Aldi
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_commodity_chem}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="aldi_compliance_pct">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Compliance level (%)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.aldi_compliance_pct | number:'1.2-2'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="connected_to_miles">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to Miles
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_miles}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="sent_on_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connection Request sent on
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.sent_on_date | date:'yyyy-MM'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status of the connection request
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.status}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="connected_to_anord">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to Aldi Einkauf
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_anord}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="connected_to_asud">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Connected to Aldi Sud KG
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.connected_to_asud}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="milesData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: milesData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingMilesDataInfo"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataMilesPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
                    
            </div>
            <div *ngIf="this.isHm">
                <div class="h-full kontoorDataTable">
                    <table mat-table [dataSource]="monthlyDataListHm" class="monthlyHmDataTable" #monthlyDataListHmMatSort="matSort" matSort>

                        <ng-container matColumnDef="chemical_identifier">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical No.</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.chemical_identifier}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="organization_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Factory Name</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.organization_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="zdhc_aid">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC AID</th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.zdhc_aid}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="scan_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Scan</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.scan_date}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemical_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of Chemical (as added in BHive CIL)
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.chemical_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="chemical_manufacturer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical Manufacturer Name (as added in BHive CIL)
                            </th>
                            <td mat-cell *matCellDef="let formula">
                                {{formula.chemical_manufacturer}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="_type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manually added / BHive Database formula</th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula._type}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="gateway_name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of Chemical matched in ZDHC Gateway
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.gateway_name}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="gateway_manufacturer">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name of Chemical Manufacturer matched in ZDHC Gateway
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.gateway_manufacturer}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="is_mixed_compliant">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL (Gateway + crowdsourced)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.is_mixed_compliant}}
                            </td>
                        </ng-container>


                        <ng-container matColumnDef="last_generated">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Generated InCheck report
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.last_generated}}
                            </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="gateway_level">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gateway Level
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.gateway_level}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="is_crowedsource_compliant">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>ZDHC MRSL (crowdsourced - BHive database)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.is_crowedsource_compliant}}
                            </td>
                        </ng-container>



                        <ng-container matColumnDef="gots">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gots
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.gots}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="gots_version">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Gots Version
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.gots_version}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="eco_passport">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Eco Passport
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.eco_passport}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="bluesign">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Bluesign
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.bluesign}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="inditex_BelowThreeYears">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inditex BelowThreeYears
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.inditex_BelowThreeYears}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="inditex_AboveThreeYears">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inditex AboveThreeYears
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.inditex_AboveThreeYears}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="toxfmd">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Toxfmd
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.toxfmd}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="scivera">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Scivera
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.scivera}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="test_report">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Test Report
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.test_report}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="is_incheck_scope">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chemical is in scope for InCheck
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.is_incheck_scope}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="factory_usage">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>General Usage indicated by factory
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.factory_usage}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="category">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.category}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="is_used_for_brand">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Marked as "Used for H&M"
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.is_used_for_brand}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="consumption">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption (kg)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{ formula.consumption != 'NA' ? formula.consumption  : 'NA'}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="stock_volume">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ending Stock (kg)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.stock_volume}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="delivered_quantity">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delivered Quantity (kg)
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.delivered_quantity}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="manufacture_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Manufacture Date
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.manufacture_date | date:'yyyy-MM-dd'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="purchase_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase Date
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.purchase_date| date:'yyyy-MM-dd'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="expiry_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.expiry_date| date:'yyyy-MM-dd'}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="lot_number">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Lot Number
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.lot_number}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="cas_nr">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Cas Nr
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.cas_nr}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="notes">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Notes
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.notes}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="use_of_ppe">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Use of PPE
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.use_of_ppe}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ghs_msds_available">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghs Msds Available
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.ghs_msds_available}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="precautionary_statement">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Precautionary Statement
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.precautionary_statement}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="hazard_statement">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghs Hazard Statement
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.hazard_statement}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="ghs_classification">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghs Classification
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.ghs_classification}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="stock_location">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Stock Location
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.stock_location}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="storage_condition">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Storage Condition
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.storage_condition}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="minimum_stock">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Minimum Stock
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.minimum_stock}}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="maximum_stock">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Maximum Stock
                            </th>
                            <td mat-cell *matCellDef="let formula"
                                >
                                {{formula.maximum_stock}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="hmData; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: hmData;"></tr>

                    </table>
                    <div *ngIf="hasNotData" style="text-align:center;">
                    No data is available
                    </div>
                    <loading-spinner *ngIf="isLoadingHmDataInfo"></loading-spinner>                       
                </div>
                <mat-paginator #monthlyDataListHmPaginator="matPaginator" sticky [pageSizeOptions]="pageSizeOptions"
                showFirstLastButtons></mat-paginator>
                    
            </div>
    </div>
    
</div>